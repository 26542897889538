@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/backgrounds' as bg;

.rule1 {
  width: 345px;
  margin-top: fn.spacing(2);
}

.button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.slider-tool-paper {
  box-shadow: none;
  position: relative;
  z-index: 2;
  background: var.$color-neutral-background-default;
  padding: fn.spacing(2);
  margin-bottom: fn.spacing(8);
  border: 1px solid var.$color-neutral-grey-5;
  border-radius: fn.spacing(1);
}

.disclaimer-container {
  padding: fn.spacing(0) fn.spacing(2);
  margin-bottom: fn.spacing(4);
}

.example-paper {
  text-align: center;
  box-shadow: none;
  background: bg.$bg-gradient-blue-2;
  color: white;
  border-radius: var.$card-mobile-border-radius;
  padding: fn.spacing(6) fn.spacing(2) fn.spacing(4);
  position: relative;
  z-index: 2;
  margin: 0 0 fn.spacing(4) 0;
}

.slider-tool-wrapper {
  width: 100%;
}

.slider-tool-title {
  text-align: left;
  margin-bottom: fn.spacing(2);
}

.slider-tool-min-mark {
  // value didn't fit within standard spacing
  transform: translateX(fn.spacing(3));
  color: var.$color-neutral-grey-3;
}

.slider-tool-max-mark {
  // value didn't fit within standard spacing
  transform: translateX(fn.spacing(-4));
  color: var.$color-neutral-grey-3;
}

.marks {
  display: none;
}

.slider-tool-stats-title {
  margin-bottom: fn.spacing(2);
}

.slider-tool-stats-title[data-disabled='true'] {
  color: var.$color-neutral-grey-3;
}

.slider-tool-value {
  color: var.$color-primary-achieve-blue;
  text-align: right;
}

.example-value {
  color: var.$color-neutral-background-default;
  padding-top: fn.spacing(1);
}

.example-title {
  padding-bottom: fn.spacing(3);
}

.example-divider {
  border-top: 1px solid var.$color-neutral-grey-3;
  padding-top: fn.spacing(2);
  margin-top: fn.spacing(2);
}

.icon-container {
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    padding: fn.spacing(3) fn.spacing(1.5);
  }
}

.paper-carousel-title-eyebrow {
  padding-bottom: fn.spacing(1);
}

.slider-divider {
  margin-bottom: fn.spacing(2);
  padding-bottom: fn.spacing(2);
}

.graph-card-container {
  background: var.$color-neutral-background-default;
  padding: fn.spacing(4) fn.spacing(2);
  border-radius: fn.spacing(1);
  min-width: 320px;
  max-width: 100%;
  border: 1px solid var.$color-neutral-grey-4;
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
}

.graph-card-divider-titles {
  margin: fn.spacing(0.5) 0;
}

.graph-card-title {
  color: var.$color-neutral-icon-onlight;
}

.graph-card-subtitle {
  color: var.$color-neutral-grey-0;
}

.graph-card-help-text {
  color: var.$color-neutral-grey-2;
  font-style: italic;
}

.graph-card-chart {
  display: flex;
  flex-direction: row;
  gap: fn.spacing(0.5);
  justify-content: center;
}

.graph-card-chart-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
  max-width: 100px;
}

.graph-card-graph-bar {
  background: #d2e2fe; // TODO: Update after color is in sunbeam variables.scss
  border-radius: fn.spacing(1) fn.spacing(1) 0 0;
  height: 70px;
  width: 100px;
}

.graph-card-graph-bar-secondary {
  background: linear-gradient(180.09deg, #30f -19.14%, #68d6f2 121.73%);
  border-radius: fn.spacing(1) fn.spacing(1) 0 0;
  height: 47px;
  width: 100px;
}

.graph-card-graph-bar-secondary[data-graph='card2'] {
  height: 28px;
}

.graph-card-graph-bar-secondary[data-graph='card3'] {
  height: 57px;
}

.graph-card-chart-legends {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.graph-card-graph-legend-text {
  color: var.$color-neutral-grey-2;
  padding: 0 fn.spacing(0.5);
  width: 100px;
  min-height: 45px;
}

.graph-card-marker {
  background: #eff5ff; // TODO: Update after color is in sunbeam variables.scss
  border-radius: fn.spacing(1);
  padding: fn.spacing(1) fn.spacing(1.5);
  display: flex;
  flex-direction: row;
  gap: fn.spacing(1);
  align-items: center;
}

.graph-card-marker-image {
  min-width: 53px;
  max-width: 53px;
  max-height: 53px;
}

.graph-card-marker-image[data-variant='awards'] {
  min-width: 24px;
  max-width: 24px;
  max-height: 50px;
}

.graph-card-marker-image[data-variant='awards']:first-of-type {
  margin-right: fn.spacing(2.5);
}

.carousel-item {
  display: none;
  opacity: 0;
  z-index: 0;
  width: 100%;

  &[data-active='true'] {
    display: block;
    z-index: 2;
    opacity: 1;
    filter: brightness(1);
  }
}

.carousel-controls {
  opacity: 0;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transform: translateY(-50%);
  top: 50%;
  padding: 0 fn.spacing(2);
  z-index: 1;
}

.carousel-items {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.customize-button {
  margin-top: fn.spacing(4);
}

.indicators {
  margin: fn.spacing(4) 0;
}

.carousel-wrapper-indicators button {
  background-color: var.$color-neutral-grey-4;
}

.carousel-wrapper-indicators {
  background-color: transparent !important;
}

.carousel-active {
  // Need !important here and in this file to override the Carousels very specific styles
  background-color: var.$color-primary-background-default !important;
}

.carousel-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: fn.spacing(2);
  align-items: center;
}

@media screen and (min-width: var.$breakpoint-md) {
  .paper-carousel-title-eyebrow {
    padding-bottom: fn.spacing(2);
  }

  .button-container {
    display: block;
    align-items: normal;
    flex-direction: row;
  }

  .rule1 {
    transform: translate(fn.spacing(4));
    width: 95%;
  }

  .slider-container {
    margin-top: fn.spacing(8); // Ensure there is space to accommodate the Badge
    justify-content: center;
  }

  .example-paper {
    border-radius: var.$card-border-radius;
    margin: 0 0 fn.spacing(2);
    padding: fn.spacing(6) fn.spacing(4);
  }

  .slider-tool-paper {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
  }

  .carousel-controls {
    opacity: 1;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }

  .rule1 {
    transform: translate(fn.spacing(8));
  }

  .graph-card-container {
    max-width: 320px;
  }

  .carousel-item {
    &[data-active='true'] {
      width: initial;
    }
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .rule1 {
    transform: translate(fn.spacing(8));
    width: 100%;
  }
}

@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0;
  background: var.$color-primary-highlight-blue2;
  max-width: 1000vh;
  overflow-x: hidden;
}

.section[data-variant-progress='true'] {
  min-height: 1140px;
}

.skinny-form-progress-spinner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: fn.spacing(6) 0;
  background: var.$color-primary-highlight-blue2;
  overflow-x: hidden;
}

@media screen and (min-width: var.$breakpoint-md) {
  .section[data-variant-progress='true'] {
    min-height: 790px;
  }
}

@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.cards-title-eyebrow,
.cards-list-item-eyebrow {
  padding-bottom: 0;
}

.cards-title-divider {
  margin-bottom: 0;
}

.reset-link {
  display: flex;
  gap: fn.spacing(0.5);
  align-items: center;
  justify-content: center;
}

.cards-list {
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
  justify-content: center;
  margin-bottom: fn.spacing(4);
  align-items: center;
}

.cards-list-item {
  display: flex;
  flex-direction: column;
  gap: fn.spacing(2);
  padding: fn.spacing(4) fn.spacing(3);
  border: 1px solid var.$color-neutral-grey-4;
  border-radius: fn.spacing(1);
  min-width: 320px;
  max-width: 320px;
  background: var.$color-neutral-white;
  box-shadow: 0 0.5px 3px 0 #10182817;
  align-items: center;
  position: relative;
}

.cards-list-item-image {
  width: 70px;
  height: 60px;
}

.cards-list-item-icons {
  display: flex;
  flex-direction: column;
  padding: fn.spacing(2.5);
  border: 1px solid var.$color-neutral-grey-5;
  border-radius: fn.spacing(1.5);
}

.cards-list-item-icons-item {
  display: flex;
  flex-direction: row;
}

.carousel-wrapper {
  padding: 0;
  margin-bottom: fn.spacing(3);
}

.carousel-item {
  padding-left: 0 !important;
}

.carousel-container {
  gap: fn.spacing(2);
}

[data-variant='carousel'] .cards-list-item {
  min-width: 280px;
  max-width: 280px;
  margin-bottom: fn.spacing(0.5);
  margin-top: fn.spacing(5);
}

@media screen and (min-width: var.$breakpoint-md) {
  [data-variant='carousel'] .cards-list-item {
    min-width: 380px;
    max-width: 380px;
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .cards-list {
    align-items: normal;
    flex-direction: row;
  }

  .cards-list-item {
    min-width: 380px;
    max-width: 380px;
  }

  .cards-list-item[data-top-choice='true'] {
    box-shadow: 0 6px 40px -4px #1018281c;
  }
}
